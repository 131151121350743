import React, {useEffect, useState} from 'react';
import './App.css';
import NodesView from "./features/CategoryTree/NodesView";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Header from "./features/Header/Header";
import Lots from "./features/Lots/Lots";
import AppleMusicAuth from "./features/AppleMusicAuth/AppleMusicAuth";
import Authorized from "./features/Authorized/Authorized";

function App() {
    function Home() {
        return <h2>Home</h2>;
    }

    function About() {
        return <h2>About</h2>;
    }

    function Users() {
        return <h2>Users</h2>;
    }

  return (
          <Router>
                  <Header/>

                  {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                  <Switch>
                      <Route path="/apple-music-auth">
                          <AppleMusicAuth />
                      </Route>
                      <Route path="/authorized/:service">
                          <Authorized />
                      </Route>
                  </Switch>
          </Router>
  );
}

export default App;
