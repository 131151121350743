import cn from "classnames";
import React, {useEffect} from "react";
import classes from "./Authorized.module.scss";
import Typography from "../UI/Typography/Typography";
import YoutubeMusicIcon from "../Icons/Logo/YoutubeMusicIcon";
import AppleMusicIcon from "../Icons/Logo/AppleMusicIcon";
import SpotifyIcon from "../Icons/Logo/SpotifyIcon";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";


export type Authorized = React.InputHTMLAttributes<HTMLInputElement> & {
};

const Authorized = ({...props}: Authorized) => {
    // @ts-ignore
    let { service } = useParams();
    console.log(service)

    useEffect(() => {
        Init();
    }, []);


    const Init = () => {
    }

    let Logo;

    if(service === "apple_music") {
        Logo = <AppleMusicIcon className={classes.logo}/>
    } else if (service === "youtube_music") {
        Logo = <YoutubeMusicIcon/>
    } else if (service === "spotify") {
        Logo =  <SpotifyIcon/>
    }
    //if()


    return (
        <div className={classes.root}>
            <div className={classes.container} >
                {Logo}
            </div>
            <Typography variant="h2" className={classes.title}>
                Authorized successfully
            </Typography>
        </div>
    );
};

export default Authorized;
