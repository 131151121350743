import cn from "classnames";
import React, {useEffect} from "react";
import mergeClasses from "../..//utils/mergeClasses";
import classes from "./AppleMusicAuthProps.module.scss";
import { useScript } from "../../hooks/useScript";
import Typography from "../UI/Typography/Typography";
import {useParams, useLocation, useHistory} from "react-router-dom";

export type AppleMusicAuthProps = React.InputHTMLAttributes<HTMLInputElement> & {
    onChangeHandler?: React.ChangeEventHandler<HTMLInputElement>;
    variant?: "outlined" | "filled";
};

const AppleMusicAuth = ({...props}: AppleMusicAuthProps) => {

    useScript("https://js-cdn.music.apple.com/musickit/v1/musickit.js", "musickit")
    let history = useHistory();

    const search = useLocation().search;
    const state = new URLSearchParams(search).get("state");

    useEffect(() => {
        Init();
    }, []);

    const appleAuth = () => {
        try {
            let music = MusicKit.getInstance();

            music.authorize().then( async (token) => {

                const headers = {
                    "charset": "UTF-8",
                    'Content-type': 'application/json',
                    "Access-Control-Allow-Origin" : "*",
                    "Origin": "*",
                    "X-User-Token-Apple": token,
                };

                await fetch(`https://api.gomusic.to/apple_music/authCallback?state=${state}&token=${token}`, { headers: headers });
                //history.push('/authorized/apple_music', )
                window.location.href = "/authorized/apple_music";
            }).catch(
                e => {
                    console.log(e); alert(e);
                });
        } catch (e) {
            alert("catch:" + e)
        }

    }

    const Init = () => {
        document.addEventListener('musickitloaded', function () {
            // MusicKit global is now defined
            MusicKit.configure({
                developerToken: "eyJhbGciOiJFUzI1NiIsImtpZCI6Ik1GNzg1NE04RjYiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE2ODEyOTgyOTksImlhdCI6MTY4MDc3OTg5OSwiaXNzIjoiUkE5SzVaU1JEWiJ9.CH5zKTznhH4GKrrzx-PysyczYYv8PGg64EtbuD99hs7l1aNQ5-Gu4CPw6XPJiY9hLD-2bSIt3WurPmklWAVWkQ",
                app: { name: 'postman', build: '1.0'}
            });
        });
    }


    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <img className={classes.logo} width={100} height={100} src="logo_apple_music.png" alt=""/>
                <Typography variant="h2" className={classes.title}>
                    Access Request
                </Typography>
                <Typography variant="h4" className={classes.description}>
                    GoMusic would like to access Apple Music for converting playlists to other players
                </Typography>
                <div className={classes.buttonContainer} onClick={appleAuth}>
                    <div className={cn(classes.authButton, classes.logIn)}>
                        LogIn to Apple Music
                    </div>
                    <div className={cn(classes.authButton, classes.notNow)}>
                        Not now
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppleMusicAuth;
