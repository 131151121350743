import * as React from "react";

const YoutubeMusicIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="#FF0000"/>
        <path d="M49.9996 26.1593C63.1474 26.1593 73.8405 36.8525 73.8405 50.0002C73.8405 63.148 63.1474 73.8412 49.9996 73.8412C36.8519 73.8412 26.1587 63.148 26.1587 50.0002C26.1587 36.8525 36.8519 26.1593 49.9996 26.1593ZM49.9996 23.8639C35.5621 23.8639 23.8633 35.5628 23.8633 50.0002C23.8633 64.4377 35.5621 76.1366 49.9996 76.1366C64.4371 76.1366 76.136 64.4377 76.136 50.0002C76.136 35.5628 64.4371 23.8639 49.9996 23.8639Z" fill="white"/>
        <path d="M40.9092 63.0504L63.0683 49.4141L40.9092 36.9141V63.0504Z" fill="white"/>
    </svg>
);

export default YoutubeMusicIcon;
