import React, {useEffect, useState} from 'react';
import styles from "./Header.module.scss"
import {Link} from "react-router-dom";

function Header() {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                {/*<div>
                    <Link className={styles.content__item} to="/lots">Лоты</Link>
                    <Link className={styles.content__item} to="/categories">Справочники</Link>
                    <Link className={styles.content__item} to="/">Облако слов</Link>
                </div>
                <div>
                    user
                </div>*/}
            </div>
        </div>
    );
}

export default Header;
