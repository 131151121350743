import * as React from "react";

const SpotifyIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.24707" y="0.537109" width="98.2619" height="99.4117" rx="49.131" fill="white"/>
        <path d="M79.5763 44.3261C63.4593 34.7545 36.8745 33.8745 21.4888 38.5447C19.0176 39.294 16.4049 37.8993 15.6567 35.4286C14.9074 32.9562 16.301 30.3452 18.7734 29.5941C36.4357 24.2331 65.7956 25.269 84.3498 36.283C86.5727 37.6019 87.3017 40.472 85.984 42.6907C84.6657 44.913 81.7932 45.6456 79.5763 44.3261ZM79.0485 58.5028C77.9183 60.3376 75.5193 60.9126 73.6869 59.7865C60.2501 51.5273 39.7601 49.1343 23.8633 53.9597C21.8023 54.5831 19.6248 53.4206 18.9985 51.3631C18.3775 49.3014 19.54 47.1281 21.5981 46.5012C39.7577 40.9903 62.3332 43.6592 77.766 53.1435C79.5984 54.272 80.1752 56.6722 79.0485 58.5028ZM72.9304 72.1177C72.0325 73.5906 70.1135 74.0528 68.6459 73.1548C56.9041 65.9786 42.1257 64.3576 24.7213 68.3335C23.0442 68.718 21.3724 67.6665 20.9903 65.99C20.6058 64.3128 21.653 62.6417 23.3338 62.2589C42.38 57.9051 58.718 59.7787 71.8969 67.8319C73.3663 68.7293 73.829 70.6489 72.9304 72.1177ZM49.9997 0C22.3862 0 0 22.3857 0 49.9994C0 77.6154 22.3862 100 49.9997 100C77.615 100 100 77.6154 100 49.9994C100 22.3857 77.615 0 49.9997 0Z" fill="#1ED760"/>
    </svg>
);

export default SpotifyIcon;
