import * as React from "react";

const AppleMusicIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100" height="100" rx="50" fill="url(#paint0_linear_1520:8806)"/>
        <path d="M39.0606 68.5422V41.7898C39.0606 40.7866 39.6179 40.1735 40.7326 39.9506L63.8065 35.2689C65.0327 35.046 65.7015 35.6033 65.813 36.9409V57.3396C65.813 59.0116 63.3049 60.1263 58.2889 60.6837C48.7583 62.1885 50.2632 78.2399 63.3049 73.8926C68.321 72.0534 69.157 67.2045 69.157 62.3557V23.2303C69.157 23.2303 69.157 19.8863 66.3146 20.7223L37.8902 26.5744C37.8902 26.5744 35.7166 26.9088 35.7166 29.584V63.5261C35.7166 65.1981 33.2085 66.3128 28.1925 66.8701C18.6619 68.375 20.1668 84.4264 33.2085 80.0791C38.2246 78.2399 39.0606 73.391 39.0606 68.5422Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_1520:8806" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FD5B73"/>
                <stop offset="1" stop-color="#FD243F"/>
            </linearGradient>
        </defs>
    </svg>

);

export default AppleMusicIcon;
